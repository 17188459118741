<template>
	<div class="InterestDoyin">
		<w-navTab titleText="抖音关注"></w-navTab>
		<div class="container">
		<div class="head">
			<div class="head-title">
				复制口令
				<br />
				打开抖音并关注
			</div>
			<div class="head-text">请按视频教程操作，并上传抖音码进行验证</div>
			<div class="head-video">
				<video poster="@/assets/img/dygzsp.png" controls src="http://shipin.zyrlzy.cn/%E6%93%8D%E4%BD%9C%E8%A7%86%E9%A2%91%E5%8E%9F%E5%A4%A7%E5%B0%8F.mp4"></video>
				<div class="head-video-btn" v-show="playbtnShow" @click="onPlay"><van-icon name="play" color="#FD2B54" size="40" /></div>
			</div>
			<div class="head-logo"><img src="@/assets/img/dylogo.png" alt="" /></div>
		</div>
		<div class="container">
			<div class="container-top">文字教程</div>
			<div class="list-item">
				<div class="item-number">1</div>
				<div class="item-text">点击复制抖音口令按钮，返回桌面打开抖音软件</div>
			</div>
			<div class="list-item">
				<div class="item-number">2</div>
				<div class="item-text">查看复制的口令码或搜索协会名称进入个人主页</div>
			</div>
			<div class="list-item">
				<div class="item-number">3</div>
				<div class="item-text">点击头像下的关注抖音按钮，返回抖音主页面</div>
			</div>
			<div class="list-item">
				<div class="item-number">4</div>
				<div class="item-text">点击抖音账号下的二维码，保存抖音码到手机</div>
			</div>
			<div class="list-item">
				<div class="item-number">5</div>
				<div class="item-text">返回义警平台，上传抖音码，等待管理员审核</div>
			</div>
		</div>
		<div class="container-footer">
			<button class="footer-btn" data-clipboard-text="6F.:/ U【上饶市平安义警协会的个人主页】长按复制此条消息，长按复制打开抖音搜索，查看TA的更多作品##Nv4WDvQdgx8##[抖音口令]" >复制抖音口令</button>
			<van-uploader :after-read="onUpload" upload-icon="plus"><div class="footer-btn">上传抖音码</div></van-uploader>
		</div>
		</div>
	</div>
</template>

<script>
import UploadApi from '@/api/upload';
import VantVendor from '@/vendor/vant';
import Clipboard from "clipboard";
import user from '@/api/user';
import Event from '@/store/event';
export default {
	name: 'InterestDoyin',
	data() {
		return {
			playbtnShow: false,
		};
	},
	created() {
		
	},
	mounted() {
		// 复制抖音口令
		var clipboard = new Clipboard('.footer-btn');
		clipboard.on('success', function(e) {
			console.info('Text:', e.text);
			e.clearSelection();
			VantVendor.Toast.success('复制成功');
		});
	},
	methods: {
		// 播放视频按钮
		onPlay() {
			this.playbtnShow = false;
		},
		// 上传抖音码并提交
		onUpload(selected) {
			VantVendor.Toast.loading('上传中');

			UploadApi.image(selected.file)
				.then(result => {
					user.tiktokStore({path:result.data.filePath})
						.then(result=>{
							VantVendor.Dialog.alert({
							  title: '上传成功',
							  message: '请勿重复提交耐心等待管理员审核',
							}).then(() => {
								this.$router.replace('/')
								Event.$emit('onDoyinPop', false);
							});
						},error=>{
							VantVendor.Toast.fail(error.msg);
						})
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				})
				.finally(() => {
					VantVendor.Toast.clear();
				});
		}
	}
};
</script>

<style lang="less" scoped>
.InterestDoyin {
	.container{
		padding: 16px 12px;
		box-sizing: border-box;
	.head {
		.head-title {
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			color: #fd2b54;
		}
		.head-text {
			font-size: 14px;
			line-height: 30px;
			color: #777777;
		}
		.head-video {
			width: 100%;
			height: 170px;
			margin: 10px 0;
			position: relative;
			video {
				width: 100%;
				height: 170px;
				// object-fit: cover;
			}
			.head-video-btn {
				position: absolute;
				top: 55px;
				left: 146px;
				width: 60px;
				height: 60px;
				background: rgba(255, 255, 255, 0.8);
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 100px;
				text-align: center;
				padding-top: 10px;
				box-sizing: border-box;
			}
		}
		.head-logo {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 130px;
			height: 145px;
		}
	}
	.container {
		padding: 15px 10px 5px;
		background: #fff;
		border-radius: 8px;

		.container-top {
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			color: #fd2b54;
		}
		.list-item {
			display: flex;
			align-items: center;
			padding: 10px 0;
			border-bottom: 0.5px solid #f2f2f2;
			&.list-item:last-child {
				border: 0;
			}
			.item-number {
				width: 24px;
				height: 24px;
				font-weight: 700;
				font-size: 14px;
				text-align: center;
				color: #f2f2f2;
				line-height: 24px;
				background: #fe2c55;
				border-radius: 100px;
			}
			.item-text {
				margin-left: 5px;
				font-size: 14px;
				line-height: 30px;
				color: #222222;
			}
		}
	}
	.container-footer {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		button{
			border: 0;
		}
		.footer-btn {
			width: 170px;
			background: #3377ff;
			border-radius: 4px;
			font-size: 16px;
			line-height: 26px;
			color: #fff;
			text-align: center;
			padding: 12px 0;
		}
	}
	}
}
</style>
